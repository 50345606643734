<template>
    <div id="container">
        <h1>Robot arm controller</h1>
        <p>This is an app to control 2 robot arm projects built usin arduino</p>
        <img :src="require('/src/assets/robot.png')" alt="">
        <div id="button" @click="redirect">Start</div>
    </div>
</template>

<script>
export default {
    methods:{
        redirect(){
            this.$router.push({name:'large'})
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
#container{
    width:100vw;
    height:100vh;
    overflow-x:hidden;
    background: rgba(0, 0, 0, 0.936);
}
h1{
    color:white;
    font-family: "Sofia";
    text-align: center;
    margin-top:6vh;
    font-size:28px;
    font-weight: 250;
}
img{
    width:90vw;
    height:50vh;
    margin-left:5vw;
    margin-top:6vh;
    margin-bottom:2vh;
}
#button{
width:50vw;
height:6vh;
margin-left:25vw;
background: rgba(255, 255, 255, 0.956);
color:black;
border-radius: 10px;
font-size:28px;
text-align: center;
line-height: 6vh;
font-family: 'Sofia';
position: fixed;
bottom:6vh;
}
p{
    color:white;
    width:80vw;
    margin-left:10vw;
    font-family: 'Sofia';
    text-align: center;
    margin-top:2vh;
    margin-bottom:4vh;
};
</style>