import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database"


const firebaseConfig = {
    apiKey: "AIzaSyCOSWRTGA3YmsSGVN1PFaQQ4Kdq-LtFMWA",
    authDomain: "robot-arm-dbc84.firebaseapp.com",
    databaseURL: "https://robot-arm-dbc84-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "robot-arm-dbc84",
    storageBucket: "robot-arm-dbc84.appspot.com",
    messagingSenderId: "431522834188",
    appId: "1:431522834188:web:86d9fe2b6f7b2accd58b29",
    measurementId: "G-42RQG7192J"
  };
const firebaseApp =initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

export default database


