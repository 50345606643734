<template>
    <div id="footer">
     <h3 class="gradient-text">Arm controller</h3>       
    </div>
</template>

<script>
export default {
    
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
h3{
    text-align: center;
    font-weight: 400;
    margin-top:2.5vh;
    font-size:32px;
    font-family: 'Sofia';
}

.gradient-text {
    /* Fallback: Set a background color. */
    background-color: #CA4246;
    
    /* Create the gradient. */
     background-image: linear-gradient(
          45deg,
          #CA4246 16.666%, 
          #E16541 16.666%, 
          #E16541 33.333%, 
          #F18F43 33.333%, 
          #F18F43 50%, 
          #8B9862 50%, 
          #8B9862 66.666%, 
          #476098 66.666%, 
          #476098 83.333%, 
          #A7489B 83.333%);
    
    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;
  
    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    
    /* Animate the text when loading the element. */
      /* This animates it on page load and when hovering out. */
      animation: rainbow-text-simple-animation-rev 0.75s ease forwards;
  
  }
  
  .gradient-text:hover{
      animation: rainbow-text-simple-animation 0.5s ease-in forwards;
  }
  
  @keyframes rainbow-text-simple-animation-rev {
    0% {
        background-size: 650%;
    }
    40% {
        background-size: 650%;
    }
    100% {
        background-size: 100%;
    }
}

/* Move the background and make it larger. */
/* Animation shown when hovering over the text. */
@keyframes rainbow-text-simple-animation {
    0% {
        background-size: 100%;
    }
    80% {
        background-size: 650%;
    }
    100% {
        background-size: 650%;
    }
}
  
</style>