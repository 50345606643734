<template>
   <v-app>
    <div id="header">
        <v-icon
        icon="mdi-alpha-l-circle"
        :size="Icon1Size"
        class="icon"
        @click="click1(1)"
      ></v-icon>
      <v-icon
      :size="Icon2Size"
  icon="mdi-alpha-s-circle"
  class="icon"
  @click="click1(2)"
></v-icon>
    </div>
   </v-app>
</template>

<script>

export default {
   data(){
    return{
        icon1Size:45,
        icon2Size:32
    }
   },
   computed:{
    Icon1Size(){
        return this.icon1Size;
    },
    Icon2Size(){
        return this.icon2Size;
    }
   },
   methods:{
    click1(val){
        if(val==1){
            this.icon1Size=45;
            this.icon2Size=32;
            this.$router.push({name:'large'})
        }
        else if(val==2){
            this.icon1Size=32;
            this.icon2Size=45;
            this.$router.push({name:'small'})
        }
    }
   }
}
</script>

<style scoped>
#header{
    width:100vw;
    position:fixed;
    bottom:0;
    left:0;
    height:8vh;
    box-shadow:2px 2px 4px rgba(0, 0, 0, 0.87);
    padding-left:20vw;
    padding-right:20vw;
    display: grid;
    grid-template-columns: repeat(2,30vw);
}
.icon{
    margin-top:2vh;
    margin-left:10vw;
}
</style>