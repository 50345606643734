import { createStore } from "vuex";
import { ref,set, get}  from "firebase/database";
import db from '../firebase'
export default createStore({
  state: {
    large1:0,
    large2:0,
    large3:0,
    large4:0,
    large5:0,
    large6:0,
    small1:0,
    small2:0,
    small3:0,
    currPage:0 // 0 is for large arm and 1 is for small arm
},
  getters: {
    getLarge1(state){
      return state.large1;
    },
    getLarge2(state){
      return state.large2;
    },
    getLarge3(state){
      return state.large3;
    },
    getLarge4(state){
      return state.large4;
    },
    getLarge5(state){
      return state.large5;
    },
    getLarge6(state){
      return state.large6;
    },
    getSmall1(state){
      return state.small1;
    },
    getSmall2(state){
      return state.small2;
    },
    getSmall3(state){
      return state.small3;
    },
    getcurrPage(state){
      return state.currPage;
    }
  },
  mutations: {
    setLarge1(state,payload){
      state.large1=payload.value;
    },
    setLarge2(state,payload){
      state.large2=payload.value;
    },
    setLarge3(state,payload){
      state.large3=payload.value;
    },
    setLarge4(state,payload){
      state.large4=payload.value;
    },
    setLarge5(state,payload){
      state.large5=payload.value;
    },
    setLarge6(state,payload){
      state.large6=payload.value;
    },
    setSmall1(state,payload){
      state.small1=payload.value;
    },
    setSmall2(state,payload){
      state.small2=payload.value;
    },
    setSmall3(state,payload){
      state.small3=payload.value;
    },
    setcurrPage(state,payload){
      state.currPage = payload.value;
    }
  },
  actions: {
    async getValues(context){
     try{
      let val1= await get(ref(db,'/' + 'large-axis-1'))
      if(val1.exists()) context.commit('setLarge1',{value:val1.val()})
      let val2= await get(ref(db,'/' + 'large-axis-2'))
      if(val1.exists()) context.commit('setLarge2',{value:val2.val()})
      let val3= await get(ref(db,'/' + 'large-axis-3'))
      if(val1.exists()) context.commit('setLarge3',{value:val3.val()})
      let val4= await get(ref(db,'/' + 'large-axis-4'))
      if(val1.exists()) context.commit('setLarge4',{value:val4.val()})
      let val5= await get(ref(db,'/' + 'large-axis-5'))
      if(val1.exists()) context.commit('setLarge5',{value:val5.val()})
      let val6= await get(ref(db,'/' + 'large-axis-6'))
      if(val6.exists()) context.commit('setLarge6',{value:val6.val()})
      let val7 = await get(ref(db,'/' + 'small-axis-1'))
      if(val7.exists()) context.commit('setSmall1',{value:val7.val()})
      let val8 = await get(ref(db,'/' + 'small-axis-2'))
      if(val8.exists()) context.commit('setSmall2',{value:val8.val()})
      let val9 = await get(ref(db,'/' + 'small-axis-3'))
      if(val9.exists()) context.commit('setSmall3',{value:val9.val()})

     }
     catch(err){
      console.log(err)
     }
    },
    async setLarge1( context,payload){
      context.commit({
        type:'setLarge1',
        value:payload.value
      })
      await set(ref(db, '/' + 'large-axis-1'),payload.value);
    },
    async setLarge2( context,payload){
      context.commit({
        type:'setLarge2',
        value:payload.value
      })
      await set(ref(db, '/' + 'large-axis-2'),payload.value);
    },
    async setLarge3( context,payload){
      context.commit({
        type:'setLarge3',
        value:payload.value
      })
      await set(ref(db, '/' + 'large-axis-3'),payload.value);
    },
    async setLarge4( context,payload){
      context.commit({
        type:'setLarge4',
        value:payload.value
      })
      await set(ref(db, '/' + 'large-axis-4'),payload.value);
    },
    async setLarge5( context,payload){
      context.commit({
        type:'setLarge5',
        value:payload.value
      })
      await set(ref(db, '/' + 'large-axis-5'),payload.value);
    },
    async setLarge6( context,payload){
      context.commit({
        type:'setLarge6',
        value:payload.value
      })
      await set(ref(db, '/' + 'large-axis-6'),payload.value);
    },
    async setSmall1( context,payload){
      context.commit({
        type:'setSmall1',
        value:payload.value
      })
      await set(ref(db, '/' + 'small-axis-1'),payload.value);
    },
    async setSmall2( context,payload){
      context.commit({
        type:'setSmall2',
        value:payload.value
      })
      await set(ref(db, '/' + 'small-axis-2'),payload.value);
    },
    async setSmall3( context,payload){
      context.commit({
        type:'setSmall3',
        value:payload.value
      })
      await set(ref(db, '/' + 'small-axis-3'),payload.value);
    }
  },
  modules: {},
});
