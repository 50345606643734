import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import colors from 'vuetify/lib/util/colors'
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: colors.cyan.darken1, // #E53935
          secondary: colors.cyan.lighten4, // #FFCDD2
        }
      },
    },
  }
})
import "@mdi/font/css/materialdesignicons.css";


createApp(App).use(store).use(router).use(vuetify).mount("#app");
