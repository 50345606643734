<template>
    <div id="container">
        <div class="slider">
            <h3 class="label">Axis1</h3>
            <v-slider class="slider"  min="0" max="180"  thumb-label  track-color="amber-lighten-3" track-fill-color="amber-lighten-1" :track-size="6" thumb-size="28"  thumb-color="amber-darken-2" v-model="val1" style="width:80vw;margin-left:4vw;"></v-slider>
        </div>
        <div class="slider">
            <h3 class="label">Axis2</h3>
            <v-slider class="slider" min="0" max="180"  thumb-label track-color="teal-lighten-3" track-fill-color="teal-lighten-1" :track-size="6" thumb-size="28" thumb-color="teal-darken-2" v-model="val2" style="width:80vw;margin-left:4vw;"></v-slider>
        </div>
        <div class="slider">
            <h3 class="label">Axis3</h3>
            <v-slider class="slider" min="0" max="180"  thumb-label track-color="lime-lighten-3" track-fill-color="lime-lighten-1" :track-size="6" thumb-size="28" thumb-color="lime-darken-2" v-model="val3" style="width:80vw;margin-left:4vw;"></v-slider>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            val1:0,
            val2:0,
            val3:0,   
        }
    },
    watch:{
        async val1(newVal){
            await this.$store.dispatch('setSmall1',{value:newVal})
        },
        async val2(newVal){
            await this.$store.dispatch('setSmall2',{value:newVal})
        },
        async val3(newVal){
            await this.$store.dispatch('setSmall3',{value:newVal})
        }
    },
    async mounted(){
        this.val1 = this.$store.getters['getSmall1']
        this.val2 = this.$store.getters['getSmall2']
        this.val3 = this.$store.getters['getSmall3']
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    height:fit-content;
    overflow-x:hidden;
    overflow-y:auto;
    margin-top:10px;
}
#container::-webkit-scrollbar{
    display: none;
}
.slider{
    width:90vw;
    margin-left:5vw;
    height:12vh !important;
}
.label{
    font-size:24px;
    font-weight:290;
    color:black;
    
}
</style>