<template>
    <div id="container">
        <div class="slider">
            <h3 class="label">Axis1</h3>
            <v-slider class="slider"  min="0" max="180"  thumb-label  track-color="red-lighten-3" track-fill-color="red-lighten-1" :track-size="6" thumb-size="28"  thumb-color="red-darken-2" v-model="val1" style="width:80vw;margin-left:4vw;"></v-slider>
        </div>
        <div class="slider">
            <h3 class="label">Axis2</h3>
            <v-slider class="slider" min="0" max="180"  thumb-label track-color="cyan-lighten-3" track-fill-color="cyan-lighten-1" :track-size="6" thumb-size="28" thumb-color="cyan-darken-2" v-model="val2" style="width:80vw;margin-left:4vw;"></v-slider>
        </div>
        <div class="slider">
            <h3 class="label">Axis3</h3>
            <v-slider class="slider" min="0" max="180"  thumb-label track-color="purple-lighten-3" track-fill-color="purple-lighten-1" :track-size="6" thumb-size="28" thumb-color="purple-darken-2" v-model="val3" style="width:80vw;margin-left:4vw;"></v-slider>
        </div>
        <div class="slider">
            <h3 class="label">Axis4</h3>
            <v-slider class="slider" min="0" max="180"  thumb-label track-color="green-lighten-3" track-fill-color="green-lighten-1" :track-size="6" thumb-size="28" thumb-color="green-darken-2" v-model="val4" style="width:80vw;margin-left:4vw;"></v-slider>
        </div>
        <div class="slider">
            <h3 class="label">Axis5</h3>
            <v-slider class="slider" min="0" max="180"  thumb-label track-color="blue-lighten-3" track-fill-color="blue-lighten-1" :track-size="6" thumb-size="28" thumb-color="blue-darken-2" v-model="val5" style="width:80vw;margin-left:4vw;"></v-slider>
        </div>
        <div class="slider">
            <h3 class="label">Axis6</h3>
            <v-slider class="slider" min="0" max="180"  thumb-label track-color="orange-lighten-3" track-fill-color="orange-lighten-1" :track-size="6" thumb-size="28" thumb-color="orange-darken-2" v-model="val6" style="width:80vw;margin-left:4vw;"></v-slider>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            val1:0,
            val2:0,
            val3:0,
            val4:0,
            val5:0,
            val6:0,
        }
    },
    watch:{
        async val1(newVal){
            await this.$store.dispatch('setLarge1',{value:newVal})
        },
        async val2(newVal){
            await this.$store.dispatch('setLarge2',{value:newVal})
        },
        async val3(newVal){
            await this.$store.dispatch('setLarge3',{value:newVal})
        },
        async val4(newVal){
            await this.$store.dispatch('setLarge4',{value:newVal})
        },
        async val5(newVal){
            await this.$store.dispatch('setLarge5',{value:newVal})
        },
        async val6(newVal){
            await this.$store.dispatch('setLarge6',{value:newVal})
        },
    },
    async mounted(){
        this.val1 = this.$store.getters['getLarge1']
        this.val2 = this.$store.getters['getLarge2']
        this.val3 = this.$store.getters['getLarge3']
        this.val4 = this.$store.getters['getLarge4']
        this.val5 = this.$store.getters['getLarge5']
        this.val6 = this.$store.getters['getLarge6']
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    height:fit-content;
    overflow-x:hidden;
    overflow-y:auto;
    margin-top:10px;
}
#container::-webkit-scrollbar{
    display: none;
}
.slider{
    width:90vw;
    margin-left:5vw;
    height:12vh !important;
}
.label{
    font-size:24px;
    font-weight:290;
    color:black;
    
}
</style>