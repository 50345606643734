<template>
<div id="baap">
<the-header v-if="Route"></the-header>
<v-fade-transition mode="out-in">
  <router-view :key="$route.fullPath"/>
</v-fade-transition>
<the-footer v-if="Route"></the-footer>
</div>  
</template>

<script>
import TheHeader from './components/header-comp.vue'
import TheFooter from './components/footer-comp.vue'
export default {
  components:{TheHeader,TheFooter},
  computed:{
    Route(){
      let currRoute = this.$route.name
      if(currRoute == 'welcome'){return false}
      else return true;
    }
  },
  async mounted(){
    await this.$store.dispatch('getValues')
  }
}
</script>

<style scoped>
#baap{
  overflow-x:hidden;
  overflow-y:hidden;
  height:100vh !important;
  width:100vw;
}
#baap::-webkit-scrollbar{
  display:none;
}
</style>