import { createRouter, createWebHistory } from "vue-router";

import LargePage from '../views/large-page.vue'
import SmallPage from '../views/small-page.vue'
import WelcomePage from '../views/welcomPage.vue'
const routes = [
  {
    path:'/large',
    name:'large',
    component:LargePage
  },
  {
  path:'/small',
  name:'small',
  component:SmallPage
  },
  {
    path:'/',
    name:'welcome',
    component:WelcomePage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
